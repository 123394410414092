// @flow strict
import React, { useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import Meta from './Meta';
import Tags from './Tags';
import styles from './Post.module.scss';
import type { Node } from '../../types';
import { useIntl } from 'gatsby-plugin-react-intl';
import scrollTo from 'gatsby-plugin-smoothscroll';

type Props = {
  post: Node
};

const Post = ({ post }: Props) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date, updated } = post.frontmatter;
  const intl = useIntl();
  const pageRef = useRef('PostTop');

  useEffect(() => {
    pageRef.current.scrollIntoView();
  });

  return (
    <div ref={pageRef} className={styles['post']}>
      {/* <Link className={styles['post__home-button']} to="/">All Articles</Link> */}

      <div className={styles['post__content']}>
        <Content body={html} title={title} />
      </div>

      <div className={styles['post__footer']}>
        <Meta date={date} updated={updated} intl={intl} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        {/* <Author /> */}
        <Link className={styles['post__top-button']} onClick={() => scrollTo('html')} to="">To top</Link>
      </div>

      <div className={styles['post__comments']}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
