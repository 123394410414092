// @flow strict
import React from 'react';
import styles from './Meta.module.scss';

type Props = {
  date: string,
  updated: string
};

const Meta = ({ date, updated, intl }: Props) => (
  <div className={styles['meta']}>
    <p className={styles['meta__date']}>{intl.formatMessage({id: 'publishedOn'})}{new Date(date).toLocaleDateString(intl.formatMessage({id: 'locale'}), { year: 'numeric', month: 'short', day: 'numeric' })}</p>
    <p className={styles['meta__date']}>{intl.formatMessage({id: 'updatedOn'})}{new Date(updated).toLocaleDateString(intl.formatMessage({id: 'locale'}), { year: 'numeric', month: 'short', day: 'numeric' })}</p>
  </div>
);

export default Meta;
